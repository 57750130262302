<template>
    <f-modal
        :title="title"
        :width="800"
        ref="FModal"
        @ok="handleOk"
        @cancel="handleCancel">
        <!--        自定义标签区域开始-->
        <a-form-model ref="ruleForm" :model="params" :rules="rules" v-bind="layout">
            <a-form-model-item has-feedback label="岗位名称" prop="PostName">
                <a-input v-model="params.PostName" type="text" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="报酬" prop="SalaryMin">
                <a-input v-model="params.SalaryMin" type="text" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="详细工作地址" prop="WorkAdd">
                <a-input v-model="params.WorkAdd" type="text" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item label="所在镇街" prop="AreaName">
                <a-select v-model="params.AreaName" placeholder="设置所在镇街">
                    <a-select-option v-for="(item, index) in areaNameList" :key="index" :value="item.AreaName">{{
                            item.AreaName
                        }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            
            <a-form-model-item label="职位描述" prop="Details">
                <FEditor v-model="params.Details" :height="300"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="联系方式" prop="LinkPhone">
                <a-input v-model="params.LinkPhone" type="number" autocomplete="off"/>
            </a-form-model-item>
            
            <a-form-model-item has-feedback label="联系人" prop="LinkMan">
                <a-input v-model="params.LinkMan" type="text" autocomplete="off"/>
            </a-form-model-item>
            
            <!--            <a-form-model-item label="学历" prop="Education">-->
            <!--                <a-select v-model="params.Education" placeholder="设置学历">-->
            <!--                    <a-select-option v-for="(item, index) in educationList" :key="index" :value="item">{{ item }}-->
            <!--                    </a-select-option>-->
            <!--                </a-select>-->
            <!--            </a-form-model-item>-->
            
            <!--            <a-form-model-item has-feedback label="期望岗位" prop="PostName">-->
            <!--                <a-cascader-->
            <!--                    v-model="params.PostName"-->
            <!--                    :field-names="postNameField"-->
            <!--                    :options="postNameList"-->
            <!--                    :display-render="postNameDisplayRender"-->
            <!--                    expand-trigger="hover"-->
            <!--                    placeholder="请选择期望岗位"-->
            <!--                    @change="onPostNameChange"-->
            <!--                />-->
            <!--            </a-form-model-item>-->
        
        </a-form-model>
        <!--        自定义标签区域结束-->
    </f-modal>
</template>

<script>
// info-edit
import FModal from "@/components/FModal";

import FEditor from "@/components/FEditor";

export default {
    name: "work-edit",
    components: {FEditor, FModal},
    data() {
        return {
            //    自定义数据区域开始
            labelCol: {span: 4},
            wrapperCol: {span: 14},
            layout: {
                labelCol: {span: 6},
                wrapperCol: {span: 16},
            },
            params: {
                AreaName: "",
                Details: "",
                LinkMan: "",
                LinkPhone: "",
                PostName: "",
                SalaryMin: "",
                WorkAdd: "",
            },
            rules: {
                AreaName: [{required: true, message: '请设置所在镇街', trigger: 'change'}],
                Details: [{required: true, message: '请输入职位描述', trigger: 'change'},],
                LinkMan: [{required: true, message: '请输入联系人', trigger: 'blur'},],
                LinkPhone: [{required: true, message: '请输入联系方式', trigger: 'blur'},],
                PostName: [{required: true, message: '请输入岗位名称', trigger: 'blur'},],
                SalaryMin: [{required: true, message: '请输入报酬', trigger: 'blur'},],
                WorkAdd: [{required: true, message: '请输入详细工作地址', trigger: 'blur'},],
            },
            educationList: [],
            areaNameList: [],
            postNameList: [],
            postNameField: {label: 'name', value: 'name', children: 'professionList'},
            //    自定义数据区域结束
            title: '编辑',
            onOk: () => {
            },
            onCancel: () => {
            }
        }
    },
    mounted() {
    },
    methods: {
        //    自定义方法区域开始
        async onSubmit() {
            await this.validate();
            
            const params = this.params;
            const url = params.Id || params.id ? '/BaseFlexible/UpdateFlexibleInfo' : '/BaseFlexible/CreateFlexibleInfo';
            const res = await $app.httpPost({
                path: url,
                params: params,
                loading: '正在提交',
            });
            if (res.ok) {
                $app.showToast({
                    title: params.Id || params.id ? '更新成功' : '创建成功',
                });
                this.onOk();
                this.$refs['FModal'].done();
            } else {
                this.$refs['FModal'].reset();
            }
            
        },
        async onShow() {
            // this.educationList = $app.getEducationOptions();
            this.areaNameList = $app.getStorageSync('areaList');
            // this.postNameList = $app.getStorageSync('businessList');
            // this.loadInfo();
            
        },
        // async loadInfo() {
        //
        // },
        postNameDisplayRender({labels}) {
            return labels[labels.length - 1];
        },
        onPostNameChange(value) {
            console.log(value);
            this.params.BusinessName = value[0] || ""
        },
        
        //    自定义方法区域结束
        
        validate() {
            return new Promise(resolve => {
                const __this = this;
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        resolve(true);
                    } else {
                        console.log('Form 校验错误');
                        __this.$refs['FModal'].reset();
                    }
                });
            })
        },
        handleOk() {
            this.confirmLoading = true;
            this.onSubmit();
        },
        handleCancel() {
            this.visible = false;
            this.onCancel();
        },
        show({params, onOk, onCancel}) {
            for (const key in params) {
                if (params[key]) {
                    this.params[key] = params[key]
                }
            }
            // this.params = params;
            this.title = params.id || params.Id ? '编辑' : '添加';
            this.$refs['FModal'].show();
            this.onOk = onOk;
            this.onCancel = onCancel;
            this.onShow();
        },
    }
}
</script>

<style scoped lang="less">
.info-edit {
}
</style>
