<template>
    <div class="component work-cell flex-col flex-j-center flex-a-start tap box-sizing">
        <!-- <image class="image"></image> -->
        <div class="flex-row flex-j-between flex-a-center" style="width: 100%;">
            <span class="title">{{ item.PostName }}</span>
            <span v-if="item.SalaryMin <= 0" class="price">薪资面议</span>
            <span v-else class="price">{{ item.SalaryMin }}</span>
        </div>
        
        <div class="flex-col flex-j-between flex-a-start" style="width: 100%;">
            <span class="des">工作地点：{{ item.WorkAdd }}</span>
            <span class="des">发布人：{{ item.LinkMan }}</span>
        </div>
        
        <div class="des">{{ item.upTimeText }}</div>
        <div class="btn-group">
            <span class="btn edit" @click="$emit('edit', item)">编辑</span>
            <span class="btn delete" @click="$emit('delete', item)">删除</span>
        </div>
    </div>
</template>

<script>
// work-cell
export default {
    name: "work-manage-cell",
    props: ['item'],
    data() {
        return {}
    },
    mounted() {
    },
    methods: {}
}
</script>

<style scoped lang="less">
.work-cell {
    transition: all 0.3s ease;
    position: relative;
    & .btn-group {
        opacity: 0;
        position: absolute;
        right: 0;
        bottom: 0.1rem;
        z-index: 10;
        font-size: 0.36rem;
        & .btn {
            padding: 0.1rem 0.2rem;
            &:hover {
                cursor: pointer;
                font-size: 0.4rem;
            }
        }
        & .edit {
            color: #39a9ed;
        }
        & .delete {
            color: #f1676d;
        }
    }
    &:hover {
        box-shadow: 0 0.12rem 0.12rem 0 rgba(0, 0, 0, 0.1);
        & .btn-group {
            opacity: 1;
        }
    }
    
}

.component {
    padding: .20rem;
    /* box-shadow: 0 0 8rem 0 rgba(0, 0, 0, 0.2); */
    border-radius: .12rem;
    background-color: #fff;
    margin-bottom: .20rem;
}

.component .image {
    width: 100%;
    min-height: 4.00rem;
    background-color: orange;
    border-radius: .12rem;
}

.component .title {
    font-size: .36rem;
    padding-bottom: .20rem;
}

.component .des {
    font-size: .32rem;
    color: #999999;
}

.price {
    color: #f1676d;
}
</style>
